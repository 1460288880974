export default {
  BUTTON_PRIMARY_CLASS: 'button button--primary cm_button-primary',
  BUTTON_SECONDARY_CLASS: 'button cm_button-secondary',

  PRODUCT_REPEATER_CLASS: 'productGrid',

  FACET_PANEL_CLASS: 'page-sidebar',
  FACET_BUTTON_CLASS: '',
  FACET_TITLE_ICON: `
    <rt-virtual rt-if="this.isCollapsed" template="icons/plus" />
    <rt-virtual rt-if="!this.isCollapsed" template="icons/minus" />`,
  FACET_INPUT_CLASS: 'form-input',

  SEARCH_BOX_CLASS: 'form',
  SEARCH_BOX_FORM_CLASS: 'form-prefixPostfix wrap',
  SEARCH_BOX_INPUT_CLASS: 'form-input',
  SEARCH_BOX_PLACEHOLDER: 'Search the store by SKU, VIN or Keyword',

  VERIFY_FITMENT_FITS: 'This Product Fits',
  VERIFY_FITMENT_NOT_FIT: 'This Product Does Not Fit',
  VERIFY_FITMENT_ICON: `
    <rt-virtual rt-if="this.fits === 'yes' || this.fits === 'universal'" template="icons/check" />
    <rt-virtual rt-if="this.fits === 'no'" template="icons/times-circled" />
    <rt-virtual rt-if="this.fits === 'unknown'" template="icons/questionmark" />
    <rt-virtual rt-if="this.fits === 'aggressive'" template="icons/exclamationmark" />`,

  SELECT_YOUR_VEHICLE: 'Shop By Vehicle',
  SELECT_YOUR_VEHICLE_BLOCK: 'Shop By <span>Vehicle</span>',
  GARAGE_BUTTON_CLASS: 'navPages-action activePage',
  GARAGE_SIZE: '<span class="garage-size countPill countPill--positive" key="garage-size">{{size}}</span>',

  SELECTS_CONTAINER_CLASS: `{this.isVehicleSelected ? 'cm_vehicle-selected' : ''}`,
};
